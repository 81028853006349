<template>
  <figure v-if="imageUri && shouldShow" class="media-left">
    <p class="image is-128x128">
      <img :src="imageUri" :alt="name" @error="noImage">
    </p>
  </figure>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    imageUri: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    shouldShow: true,
  }),
  methods: {
    noImage() {
      this.shouldShow = false;
    },
  },
};
</script>
